/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop */

/*1800px+  big desktops  //1rem = 12px*/
@media only screen and (min-width: 1800px) {
  html {
    font-size: 12px;
  }
}

/*1200px Tablet landscape //1 rem = 10px */
@media only screen and (max-width: 1200px) {
  html {
    font-size: 10px;
  }
  .row {
    padding: 0 2%;
  }
  .box-questions {
    width: 80%;
    margin-left: 10%;
  }
  .box-report {
    width: 80%;
    margin-left: 10%;
  }
}

/*900px Tablet portrait //1 rem = 10px */
@media only screen and (max-width: 900px) {
  html {
    font-size: 10px;
  }

  .header__text-box {
    width: 35%;
    margin-left: 32.5%;
  }

  .header-main-button {
    margin-bottom: 2rem;
    margin-right: 0rem;
  }

  .sidebar-logo {
    height: 8rem;
  }

  .d3-chart {
    height: 40rem;
    width: 70rem;
  }
}

/*600px Phone //1 rem = 9px*/
@media only screen and (max-width: 600px) {
  html {
    font-size: 9px;
  }

  .d3-chart {
    height: 35rem;
    width: 58rem;
  }
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 8px;
  }

  .d3-chart {
    height: 35rem;
    width: 50rem;
  }
}
