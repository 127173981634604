header nav {
  position: absolute;
  height: 6rem;
  top: 0rem;
  width: 100%;
  background-color: #9bb2d1;
}

.header__logo-box {
  position: absolute;
  top: 1rem;
  left: 2rem;
}

.header__logo {
  height: 3.5rem;
}

.header__userinfo {
  position: absolute;
  top: 0.3rem;
  right: 8rem;
  color: #fff;
  height: 6rem;
  text-align: right;
}

.login_button {
  margin-top: 1rem;
}

.header__text-box {
  width: 100%;
  text-align: left;
  /*padding-left: 8rem; */
  /*  border: 1px solid #fff;
 background-color: #e6e1e1; */
}

.header-main-button {
  margin-right: 3rem;
}
