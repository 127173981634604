.btn {
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
  border-radius: 1rem;
  background-color: #9d6c19;
  color: #f3fafe;
  padding: 0.5rem 0.6rem;
}

.btn-medium {
  font-size: 1.3rem;
}

.btn-small {
  font-size: 1.1rem;
}

.btn-disabled {
  background-color: grey !important;
  cursor: none !important;
}

.btn:hover {
  transform: scaleX(1.1) scaleY(1.1);
}

.radio-btn {
  margin-right: 0.4rem;
}
