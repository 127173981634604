.heading-primary {
  display: inline-block;
  width: 100%;
  font: small-caps 4rem cursive;
  letter-spacing: 0.5rem;
  color: #9d6c19;
  margin-bottom: 8rem;
}

.heading-secondary {
  font-size: 1.8rem;
  font-weight: 700;
  display: inline-block;
}

.heading-tertiary {
  font-size: 1.7rem;
  font-weight: 700;
}

.heading-report-item {
  font-size: 1.6rem;
  font-weight: 700;
}

h4 {
  font-size: 1.6rem;
  font-weight: 400;
  margin: 1rem 1rem;
}

.paragraph {
  font-size: 1.6rem;
}

.list {
  font-size: 1.6rem;
}

label {
  margin-right: 1rem;
  font-size: 1.6rem;
}

input,
textarea {
  font-size: 1.6rem;
  border: 1px solid #a4a4a4;
  border-radius: 0.3rem;
  padding-left: 1rem;
}

.italics-text {
  font-size: 1.4rem;
  font-weight: 400;
  font-style: oblique;
}

*:focus {
  outline: none;
}
