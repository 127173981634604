.section-list-data {
  background-color: #f7f7f7;
  /*  background-color:#e6e1e1; */
}

.section-home {
  background-color: #f7f7f7;
  padding: 8rem 4rem;
  height: 100%;
}

.home-page-img {
  width: 100%;
  height: 30rem;
}

.visual-data-img {
  width: 100%;
  height: 25rem;
}

.box-app-details {
  width: 95%;
  margin-left: 2.5%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 2rem;
  border: 1px solid #fff;
  background-color: #eeeeee;
  border-radius: 1rem;
}

.section-choose-kid {
  background-color: #eeeeee;
  padding: 6rem 0;
}

/*List kid profiles for user */
.list-kids {
  width: 100%;
  display: block;
  font-size: 1.6rem;
  margin-left: 3rem;
}

.radio-btn {
  padding-right: 1rem;
}

/*Add new kid's profile */

.add-kid {
  margin-top: 0.5rem;
  padding: 5rem 3rem;
  font-size: 1.5rem;
  color: #5e5e5e;
}

.kid-name-label {
  margin: 0.5rem;
  padding: 0.5rem;
}

.kid-name-input {
  margin: 0 0.5rem;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.box-questions {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #a4a4a4;
  background-color: #eeeeee;
  border-radius: 1rem;
}

.question {
  font-size: 1.5rem;
  margin: 0.2rem 3rem;
}

.itch-level {
  margin-left: 0.6rem;
}

.notes {
  padding: 0.5rem;
  overflow-y: scroll;
  display: block;
  width: 100%;

  resize: none;
}

.notes::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.notes::-webkit-scrollbar-thumb {
  border: 1px solid #5e5e5e;
  border-radius: 4px;
  background-color: #5e5e5e;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.table {
  border-spacing: 0 1.3rem; /*horizontal spacing*/
}

.td-label {
  margin-left: 3rem;
}

.td-input-col {
  width: 100%;
}
.td-input {
  width: 90%;
}

/*Display report*/
.box-report {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 3rem;
  padding: 1rem;
  border: 1px solid #a4a4a4;
  background-color: #eeeeee;
  //background-color: #fff;
  border-radius: 1rem;
}

.report-align-text {
  text-align: left;
  padding-left: 3rem;
}

.report-item {
  font-size: 1.6rem;
  margin: 0.1rem 0;
}

.report-item-text {
  padding-left: 1rem;
}

.report-date {
  color: #f3fafe;
  padding-left: 3rem;
}

.box-existing-symptoms {
  width: 90%;
  margin: 1rem 2rem;
  padding: 0.3rem 1rem;
  border: 1px solid #a4a4a4;
  background-color: #fff;
  border-radius: 0.5rem;
}
