.box-report-chart {
  width: 100%;
  height: auto;

  //margin-left: 5%;
  margin-bottom: 1rem;
  padding: 1rem;
  overflow-x: scroll;
}


.chart-container {
  width: 100%;
  height: auto;
  text-align: center;
}

.d3-chart {
  height: 50rem;
  width: 80rem;
}

.d3-title {
  font-size: 1.9rem;
}

.axis-label {
  font-size: 1.6rem;
}

.axis-tick-text {
  font-size: 1.2rem;
}
